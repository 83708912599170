const initialState = {
    loading: false,
    err: null,
    Planned_Surgeries: [],
    Performed_Surgeries: [],
    Surgeries: []
}

const SurgeryReducer = (state = initialState, action) => {
    if (action.type.includes("SURGERY") && action.type.includes("REQUEST")) return {...state, loading: true}
    else if (action.type.includes("SURGERY") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
    else {
        switch (action.type) {
            case "PATIENT_SURGERY_SUCCESS":
                return {...state, loading: false, Planned_Surgeries: action.planned, Performed_Surgeries: action.performed}
            case "SURGERY_ADD_SUCCESS":
                return {...state, loading: false}
            case "ALL_SURGERY_SUCCESS":
                return {...state, Surgeries: action.data}  
            default:
                return {...state}
        }
    }
}

export default SurgeryReducer