const initialState = {
    Patient_Drugs: [],
    Drugs: [],
    loading: false,
    err: null
}

const DrugsReducer = (state = initialState, action) => {
    if (action.type.includes("DRUG") && action.type.includes("REQUEST")) return {...state, loading: true}
    else if (action.type.includes("DRUG") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
    else {
        switch (action.type) {
            case "PATIENT_DRUGS_SUCCESS":
                return {...state, loading: false, Patient_Drugs: action.data}
            case "UNACTIVE_DRUG_SUCCESS":
                return {...state, loading: false}
            case "ALL_DRUGS_SUCCESS":
                return {...state, Drugs: action.data}
            case "ADD_DRUG_SUCCESS":
                return {...state, loading: false}         
            default:
                return {...state}
        }
    }
}

export default DrugsReducer