// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import emr from './emr'
import erx from './erx'
import lab from './lab'
import labFd from './labFd'
import pathoFd from './pathoFd'
import radioFd from './radioFd'
import radio from './radio'
import pathelogy from './pathelogy'
import drugs from './drugs'
import visit from './visit'
import appoints from './appoints'
import pharmcist from './pharmcist'
import uploader from './uploader'
import admin from './admin'
import admin_doctor from './admin_doctor'
import doctor from './doctor'
import main_admin from './main_admin'
import other_health from './other_health'
import sms from './sms'
import clinic from './clinic'
import registeral from './registeral'
import vendor from './vendor'
import recommendations from './recommendations'
import {baseReducer as clinicalDashboardReducer} from './clinicalDashboard/baseReducer'
import {baseReducer as patientReducer} from './patient/baseReducer'
import {baseReducer as typesReducer} from './types/baseReducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  emr,
  erx,
  lab,
  radio,
  pathelogy,
  drugs,
  visit,
  labFd,
  pathoFd,
  radioFd,
  uppy: uploader,
  appoints,
  pharmcist,
  admin,
  admin_doctor,
  main_admin,
  other_health,
  doctor,
  sms,
  clinic,
  registeral,
  vendor,
  recommendations,
  ...clinicalDashboardReducer,
  ...patientReducer,
  ...typesReducer
})

export default rootReducer
