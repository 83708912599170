const initialState = {
  loading: false,
  doctors: [],
  doctors_filter: [],
  items:1,
  nurses: [],
  nurses_filter: [],
  nurses_items:1,
  err: null,
  sms_code: null
}

const DrManagerReducer = (state = initialState, action) => {
  if (action.type.includes("DRMANAGER") && action.type.includes("REQUEST")) return {...state, loading: true}
  else if (action.type.includes("DRMANAGER") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
  else {
      switch (action.type) {
          case "GET_DRMANAGER_DOCTORS_SUCCESS":
              return {...state, loading: false, doctors: action.data, items: action.items}  
          case "SEARCH_DRMANAGER_DOCTORS_SUCCESS":
              return {...state, loading: false, doctors_filter: action.data}
          case "GET_DRMANAGER_NURSES_SUCCESS":
            return {...state, loading: false, nurses: action.data}  
          case "SEARCH_DRMANAGER_NURSES_SUCCESS":
            return {...state, loading: false, nurses_filter: action.data}
          case "DRMANAGER_SMS_SUCCESS":
            return {...state, sms_code: action.data.code, loading: false}  
          default:
              return {...state}
      }
  }
}

export default DrManagerReducer
