const initialState = {
    err: null,
    loading: false,
    visit_add: null
}

const VisitReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_VISIT_REQUEST":
            return {...state, loading: true}
        case "ADD_VISIT_SUCCESS":
            return {...state, visit_add: action.data, loading: false}    
        case "ADD_VISIT_FAILED":
            return {...state, err: action.err, loading: false}    
        default:
            return {...state}

    }
}

export default VisitReducer