const initialState = {
    upload_loading : false,
    upload_err : null
}

const uploadReducer = (state = initialState, action) => {
    switch (action.type) {

    case 'PATHOFD_UPLOAD_REQUEST':
            return {
                ...state,
                upload_loading: true
            }
    case 'PATHOFD_UPLOAD_SUCCESS':
            return {
                ...state,
                upload_loading: false
              }
    case 'PATHOFD_UPLOAD_FAILED':
            return {
                ...state,
                upload_err: action.err,
                upload_loading: false
              }
    case 'RADIOFD_UPLOAD_REQUEST':
              return {
                  ...state,
                  upload_loading: true
              }
    case 'RADIOFD_UPLOAD_SUCCESS':
              return {
                  ...state,
                  upload_loading: false
                }
    case 'RADIOFD_UPLOAD_FAILED':
              return {
                  ...state,
                  upload_err: action.err,
                  upload_loading: false
                }
    case 'LABFD_UPLOAD_REQUEST':
              return {
                  ...state,
                  upload_loading: true
              }
    case 'LABFD_UPLOAD_SUCCESS':
              return {
                  ...state,
                  upload_loading: false
                }
    case 'LABFD_UPLOAD_FAILED':
              return {
                  ...state,
                  upload_err: action.err,
                  upload_loading: false
                }
    default:
        return state                      
    }
}

export default uploadReducer