// **  Initial State
const initialState = {
    PathoFd_Results: [],
    PathoFd_Accepted:[],
    PathoFd_NotAccepted:[],
    err : null,
    loading: false
  }
  
  const pathoReducer = (state = initialState, action) => {
    if (action.type.includes("PATHOFD") && action.type.includes("REQUEST")) return {...state, loading: true}
    else if (action.type.includes("PATHOFD") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
    else {
      switch (action.type) {
        case 'PATHOFD_ORDER_ACCEPTED_SUCCESS':
                return {
                    ...state,
                    PathoFd_Accepted: action.data,
                    loading: false
                  }
        case 'PATHOFD_ORDER_NOTACCEPTED_SUCCESS':
                return {
                    ...state,
                    PathoFd_NotAccepted: action.data,
                    loading: false
                  }
        case 'PATHOFD_ORDER_RESULTS_SUCCESS':
                return {
                    ...state,
                    PathoFd_Results: action.data,
                    loading: false
                  }
        case 'PATHOFD_ACCEPT_ORDER_SUCCESS':
                return {
                    ...state,
                    loading: false
                  }                    
        default:
          return state
      }
    }
  }
  
  export default pathoReducer
  