// **  Initial State
const initialState = {
    LabFd_Results: [],
    LabFd_Accepted:[],
    LabFd_NotAccepted:[],
    err : null,
    loading: false
  }
  
  const labReducer = (state = initialState, action) => {
    if (action.type.includes("ULABFD") && action.type.includes("REQUEST")) return {...state, loading: true}
    else if (action.type.includes("ULABFD") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
    else {
      switch (action.type) {
        case 'ULABFD_ORDER_ACCEPTED_SUCCESS':
                return {
                    ...state,
                    LabFd_Accepted: action.data,
                    loading: false
                  }
        case 'ULABFD_ORDER_NOTACCEPTED_SUCCESS':
                return {
                    ...state,
                    LabFd_NotAccepted: action.data,
                    loading: false
                  }
        case 'ULABFD_ORDER_RESULTS_SUCCESS':
                return {
                    ...state,
                    LabFd_Results: action.data,
                    loading: false
                  }
        case 'ULABFD_ACCEPT_ORDER_SUCCESS':
                return {
                    ...state,
                    loading: false
                  }    
        case "ULABFD_UPDATE_SUCCESS":
                return {
                  ...state,
                  loading: false
                }                 
        default:
          return {...state}
      }
    }
  }
  
  export default labReducer
  