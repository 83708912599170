const initialState = {
    loading: false,
    doctorExist: false
  }
  
  const ClinicReducer = (state = initialState, action) => {
    if (action.type.includes("CLINIC") && action.type.includes("REQUEST")) return {...state, loading: true}
    // else if (action.type.includes("CLINIC") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
    else {
        switch (action.type) {
            case "CLINIC_EXIST_SUCCESS":
                return {...state, loading: false, doctorExist: true}  
            case "CLINIC_EXIST_FAILED":
                return {...state, loading: false, doctorExist: false}
            case "CLINIC_SETUP_SUCCESS":
              return {...state, loading: false}  
            case "CLINIC_SETUP_FAILED":
              return {...state, loading: false}  
            default:
                return {...state}
        }
    }
  }
  
  export default ClinicReducer
  