const initialState = {
    loading: false,
    err: null,
    Visits: []
}

const visitsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "PATIENT_VISITS_REQUEST":
            return {...state, loading: true}
        case "PATIENT_VISITS_SUCCESS":
            return {...state, loading: false, Visits: action.data}
        case "PATIENT_VISITS_FAILED":
            return {...state, loading: false, err: action.err}
        default:
            return {...state}
    }
}

export default visitsReducer