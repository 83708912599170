// **  Initial State
const initialState = {
    Prescriptions: [],
    Patient_Prescriptions: [],
    err : null,
    loading: false
  }
  
  const erxReducer = (state = initialState, action) => {
    if (action.type.includes("ERX") && action.type.includes("REQUEST")) return {...state, loading: true}
    else if (action.type.includes("ERX") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
    else {
      switch (action.type) {
          case 'ERX_DOCTOR_SUCCESS':
              return {
                ...state,
                Prescriptions: action.data,
                loading: false
              }
        case 'ERX_PATIENT_SUCCESS':
            return {
                ...state,
                Patient_Prescriptions: action.data,
                loading: false
              } 
        default:
          return {...state}
      }
    }
  }
  
  export default erxReducer
  