const initialState = {
  loading: false,
  err: null,
  doctorCheck: null,
  openPreview: false
}

const DrReducer = (state = initialState, action) => {
  if (action.type.includes("IDOCTOR") && action.type.includes("REQUEST")) return { ...state, loading: true }
  else if (action.type.includes("IDOCTOR") && action.type.includes("FAILED")) return { ...state, err: action.err, loading: false }
  else {
    switch (action.type) {
      case "IDOCTOR_CREATE_SUCCESS":
        return {
          ...state,
          loading: false,
          openPreview: true
        }
      case "IDOCTOR_UPDATE_SUCCESS":
        return {
          ...state,
          loading: false
        }
      case "IDOCTOR_CHECK_SUCCESS":
        return { ...state, doctorCheck: action.data, loading: false }
      default:
        return { ...state }
    }
  }
}

export default DrReducer
